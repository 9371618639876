import React from "react"
import { graphql } from "gatsby"

import SEO from "../../components/shell/seo"
import Layout from "../../components/shell/layout"
import SupportId from "../../components/help/pageContents/SupportId"

function SupportIdPage({ params, data }) {
  return (
    <Layout>
      <SEO title="Help" />
      {params.supportId && (
        <SupportId
          content={data.helpJson.Support.contents.find(
            i => i.id === params.supportId
          )}
          data={data.helpJson.Support.contents}
        />
      )}
    </Layout>
  )
}
export default SupportIdPage

export const query = graphql`
  {
    helpJson {
      Support {
        contents {
          question
          id
          answer {
            sub_title
            sub_description
            image {
              alt
              src {
                childImageSharp {
                  fluid {
                    srcSet
                  }
                }
              }
            }
          }
        }
        title
      }
    }
  }
`
