import { Container, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Alink from "../../common/Alink";
import Image from "../../common/Image";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "white",
    padding: "40px 14px 610px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingBottom: "370px",
    },
  },
  box1: {
    [theme.breakpoints.down("md")]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: "15px",
    },
    [theme.breakpoints.up("md")]: {
      width: "33.33333%",
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  box2: {
    [theme.breakpoints.up("md")]: {
      width: "70%",
      paddingLeft: "3%",
    },
  },
  supportTitle: {
    marginBottom: "10px",
    color: "",
  },
  supportContent: {
    textAlign: "justify",
    margin: "10px auto",
  },
  linkHove: {
    textDecoration: "none",
    color: "green",
    "&:hover": {
      color: "#488BE9",
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: "#488BE9",
    },
  },
  linkTitle: {
    textDecoration: "none",
    color: "inherit",
  },
  img: {
    width: "350px",
    height: "350px",
    objectFit: "cover",
    display: "block",
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      width: "450px",
      height: "450px",
    },
    "@media screen and (max-width: 400px)": {
      width: "250px",
      height: "250px",
    },
  },
  contentQuestion: {
    marginBottom: "30px",
    marginTop: "30px",
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
      textAlign: "center",
    },
  },
  sub_title: {
    fontWeight: "bold",
  },
  sub_description: {
    margin: "10px auto",
  },
  line: {
    marginTop: "-10px",
    marginBottom: "20px",
  },
}));

export default function Support(props) {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Container className={classes.box1}>
        <Typography
          variant="h4"
          color="primary"
          className={classes.supportTitle}
        >
          <Alink className={classes.linkTitle} to={`/help`}>
            Hỗ trợ
          </Alink>
        </Typography>
        <hr color="#2196f3" className={classes.line} />
        {props.data.map((item) => {
          return (
            <Typography variant="body2" className={classes.supportContent}>
              {item.id === props.content.id ? (
                <Alink className={classes.linkHove} to={`/help/${item.id}`}>
                  {item.question}
                </Alink>
              ) : (
                <Alink className={classes.link} to={`/help/${item.id}`}>
                  {item.question}
                </Alink>
              )}
            </Typography>
          );
        })}
      </Container>

      <Container className={classes.box2}>
        <Typography
          variant="h5"
          color="primary"
          className={classes.contentQuestion}
        >
          {props.content.question}
        </Typography>
        {props.content.answer.map((item) => {
          return (
            <div>
              <Typography variant="body1" className={classes.sub_title}>
                {item.sub_title}
              </Typography>
              <Typography variant="body1" className={classes.sub_description}>
                {item.sub_description}
              </Typography>
              {item.image && (
                <Image
                  className={classes.img}
                  src={item.image.src}
                  alt={item.image.alt}
                />
              )}
            </div>
          );
        })}
      </Container>
    </div>
  );
}
